import i18n from '@/i18n'
import Store from '@/store'
// import ReportHeading from '@/Utils/report-head-traning'
// import { dateFormat } from '@/Utils/fliter'
import { time12Formate } from '@/Utils/fliter'
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFontsBn from 'pdfmake/build/vfs_fonts_bn'
import pdfFontsEn from 'pdfmake/build/vfs_fonts_en'

const exportPdfDetails = async (baseUrl, uri = '/report-heading/detail', orgId, reportTitle, market, vm, pdfImage) => {
    try {
      Store.commit('mutateCommonProperties', {
        loading: true
      })
      if (i18n.locale === 'bn') {
        pdfMake.vfs = pdfFontsBn.pdfMake.vfs
      } else {
          pdfMake.vfs = pdfFontsEn.pdfMake.vfs
      }
    //   const reportHeadData = await ReportHeading.getReportHead(baseUrl, uri, orgId)
        const pdfContent = [
          // { columns: reportHeadData.reportHeadColumn },
          // { text: reportHeadData.orgName, style: 'org', alignment: 'center' },
          // { text: reportHeadData.projectName, style: 'address', alignment: 'center' },
          // { text: reportHeadData.address, style: 'address', alignment: 'center' },
          { text: reportTitle, style: 'hh', alignment: 'center' }
        ]
        pdfContent.push({
            table: {
                style: 'tableData',
                headerRows: 0,
                widths: ['15%', '2%', '33%', '20%', '2%', '28%'],
                body: [
                  [
                    { text: vm.$t('crop_price_config.name_foundation_location_market'), style: 'th1', alignment: 'center', colSpan: 6 },
                    {},
                    {},
                    {},
                    {},
                    {}
                  ],
                  [
                    { text: vm.$t('crop_price_config.market_name'), style: 'th', alignment: 'left' },
                    { text: ':', style: 'th', alignment: 'center' },
                    { text: vm.$i18n.locale === 'en' ? market.market_name : market.market_name_bn, style: 'td', alignment: 'left' },
                    { text: vm.$t('crop_price_config.foundation_year'), style: 'th', alignment: 'left' },
                    { text: ':', style: 'th', alignment: 'center' },
                    { text: vm.$n(market.foundation_year, { useGrouping: false }), style: 'td', alignment: 'left' }
                    // { image: 'data:image/png;base64,' + market.pdf_image, style: 'td', alignment: 'left', width: 120 }
                  ],
                  [
                    { text: vm.$t('globalTrans.photo'), style: 'th', alignment: 'left', rowSpan: 4 },
                    // { text: ':', style: 'th', alignment: 'center', rowSpan: 4 },
                      { image: 'data:image/png;base64,' + market.pdf_image, style: 'td', alignment: 'left', width: 170, rowSpan: 4, colSpan: 2 },
                    {},
                    { text: vm.$t('crop_price_config.Village'), style: 'th', alignment: 'left' },
                    { text: ':', style: 'th', alignment: 'center' },
                    { text: vm.$i18n.locale === 'en' ? market.village : market.village, style: 'td', alignment: 'left' }
                  ],
                    [
                        {},
                        {},
                        {},
                    { text: vm.$t('crop_price_config.PostOffice'), style: 'th', alignment: 'left' },
                    { text: ':', style: 'th', alignment: 'center' },
                    { text: vm.$i18n.locale === 'en' ? market.post_office : market.post_office, style: 'td', alignment: 'left' }
                  ],
                    [
                        {},
                        {},
                        {},
                    { text: vm.$t('crop_price_config.p_t_u'), style: 'th', alignment: 'left' },
                    { text: ':', style: 'th', alignment: 'center' },
                    { text: vm.$i18n.locale === 'en' ? market.union : market.union, style: 'td', alignment: 'left' }
                  ],
                    [
                        {},
                        {},
                        {},
                    { text: vm.$t('crop_price_config.MarketType'), style: 'th', alignment: 'left' },
                    { text: ':', style: 'th', alignment: 'center' },
                    { text: vm.$i18n.locale === 'en' ? market.market_type_name : market.market_type_name_bn, style: 'td', alignment: 'left' }
                  ],
                  [
                    { text: vm.$t('crop_price_config.dicrtict_division'), style: 'th1', alignment: 'center', colSpan: 6 },
                    {},
                    {},
                    {},
                    {},
                    {}
                  ],
                  [
                    { text: vm.$t('globalTrans.division'), style: 'th', alignment: 'left' },
                    { text: ':', style: 'th', alignment: 'center' },
                    { text: vm.$i18n.locale === 'en' ? market.division_name : market.division_name_bn, style: 'td', alignment: 'left' },
                    { text: vm.$t('globalTrans.district'), style: 'th', alignment: 'left' },
                    { text: ':', style: 'th', alignment: 'center' },
                    { text: (i18n.locale === 'en') ? market.district_name : market.district_name_bn, style: 'td', alignment: 'left' }
                  ],
                  [
                    { text: vm.$t('globalTrans.upazila'), style: 'th', alignment: 'left' },
                    { text: ':', style: 'th', alignment: 'center' },
                    { text: vm.$i18n.locale === 'en' ? market.upazilla_name : market.upazilla_name_bn, style: 'td', alignment: 'left' },
                    {},
                    {},
                    {}
                  ],
                  [
                    { text: vm.$t('crop_price_config.market_representative_information'), style: 'th1', alignment: 'center', colSpan: 6 },
                    {},
                    {},
                    {},
                    {},
                    {}
                  ],
                  [
                    { text: vm.$t('crop_price_config.name'), style: 'th', alignment: 'left' },
                    { text: ':', style: 'th', alignment: 'center' },
                    { text: vm.$i18n.locale === 'en' ? market.market_representative_name : market.market_representative_name, style: 'td', alignment: 'left' },
                    { text: vm.$t('crop_price_config.tel_mobile_number'), style: 'th', alignment: 'left' },
                    { text: ':', style: 'th', alignment: 'center' },
                    { text: vm.$n('0') + vm.$n(market.market_representative_mobile, { useGrouping: false }), style: 'td', alignment: 'left' }
                  ],
                  [
                    { text: vm.$t('crop_price_config.total_market_area'), style: 'th1', alignment: 'center', colSpan: 6 },
                    {},
                    {},
                    {},
                    {},
                    {}
                  ],
                  [
                    { text: vm.$t('crop_price_config.GovtCovered'), style: 'th', alignment: 'left' },
                    { text: ':', style: 'th', alignment: 'center' },
                    { text: vm.$n(market.govt_covered, { useGrouping: false }), style: 'td', alignment: 'left' },
                    { text: vm.$t('crop_price_config.GovtOpen'), style: 'th', alignment: 'left' },
                    { text: ':', style: 'th', alignment: 'center' },
                    { text: vm.$n(market.govt_open, { useGrouping: false }), style: 'td', alignment: 'left' }
                  ],
                  [
                    { text: vm.$t('crop_price_config.PrivateCovered'), style: 'th', alignment: 'left' },
                    { text: ':', style: 'th', alignment: 'center' },
                    { text: vm.$n(market.private_covered, { useGrouping: false }), style: 'td', alignment: 'left' },
                    { text: vm.$t('crop_price_config.PrivateOpen'), style: 'th', alignment: 'left' },
                    { text: ':', style: 'th', alignment: 'center' },
                    { text: vm.$n(market.private_open, { useGrouping: false }), style: 'td', alignment: 'left' }
                  ],
                  [
                    { text: vm.$t('crop_price_config.number_of_stall_shed'), style: 'th1', alignment: 'center', colSpan: 6 },
                    {},
                    {},
                    {},
                    {},
                    {}
                  ],
                  [
                    { text: vm.$t('crop_price_config.ShedNo'), style: 'th', alignment: 'left' },
                    { text: ':', style: 'th', alignment: 'center' },
                    { text: vm.$n(market.shed_no, { useGrouping: false }), style: 'td', alignment: 'left' },
                    { text: vm.$t('crop_price_config.ShedArea'), style: 'th', alignment: 'left' },
                    { text: ':', style: 'th', alignment: 'center' },
                    { text: vm.$n(market.shed_area, { useGrouping: false }), style: 'td', alignment: 'left' }
                  ],
                  [
                    { text: vm.$t('crop_price_config.StallCountAgr'), style: 'th', alignment: 'left' },
                    { text: ':', style: 'th', alignment: 'center' },
                    { text: vm.$n(market.stall_no_agri, { useGrouping: false }), style: 'td', alignment: 'left' },
                    { text: vm.$t('crop_price_config.StallCountNonAgr'), style: 'th', alignment: 'left' },
                    { text: ':', style: 'th', alignment: 'center' },
                    { text: vm.$n(market.stall_no_nonagri, { useGrouping: false }), style: 'td', alignment: 'left' }
                  ],
                  [
                    { text: vm.$t('crop_price_config.hatt_day_and_time'), style: 'th1', alignment: 'center', colSpan: 6 },
                    {},
                    {},
                    {},
                    {},
                    {}
                  ],
                  [
                    { text: vm.$t('crop_price_config.HatDays'), style: 'th', alignment: 'left' },
                    { text: ':', style: 'th', alignment: 'center' },
                    { text: vm.$i18n.locale === 'en' ? market.hat_day : market.hat_day_bn, style: 'td', alignment: 'left' },
                    { text: vm.$t('crop_price_config.market_time_from'), style: 'th', alignment: 'left' },
                    { text: ':', style: 'th', alignment: 'center' },
                    { text: time12Formate(market.market_time_from), style: 'td', alignment: 'left' }
                  ],
                  [
                    { text: vm.$t('crop_price_config.market_time_to'), style: 'th', alignment: 'left' },
                    { text: ':', style: 'th', alignment: 'center' },
                    { text: time12Formate(market.market_time_to), style: 'td', alignment: 'left' },
                    {},
                    {},
                    {}
                  ],
                  [
                    { text: vm.$t('crop_price_config.avalibility_facilities'), style: 'th1', alignment: 'center', colSpan: 6 },
                    {},
                    {},
                    {},
                    {},
                    {}
                  ],
                  [
                    { text: vm.$t('crop_price_config.infrastructure'), style: 'th', alignment: 'left' },
                    { text: ':', style: 'th', alignment: 'center' },
                    { text: vm.$i18n.locale === 'en' ? market.infa : market.infa_bn, style: 'td', alignment: 'left' },
                    { text: vm.$t('crop_price_config.communcication_Linkage'), style: 'th', alignment: 'left' },
                    { text: ':', style: 'th', alignment: 'center' },
                    { text: vm.$i18n.locale === 'en' ? market.communication : market.communication_bn, style: 'td', alignment: 'left' }
                  ],
                  [
                    { text: vm.$t('crop_price_config.buyer_seller'), style: 'th1', alignment: 'center', colSpan: 6 },
                    {},
                    {},
                    {},
                    {},
                    {}
                  ],
                  [
                    { text: vm.$t('crop_price_config.BuyerSellecount'), style: 'th', alignment: 'left' },
                    { text: ':', style: 'th', alignment: 'center' },
                    { text: vm.$n(market.number_of_buyers, { useGrouping: false }), style: 'td', alignment: 'left' },
                    { text: vm.$t('crop_price_config.ProduceAssemblage'), style: 'th', alignment: 'left' },
                    { text: ':', style: 'th', alignment: 'center' },
                    { text: vm.$n(market.number_of_sellers, { useGrouping: false }), style: 'td', alignment: 'left' }
                 ],
                  [
                    { text: '', style: 'th2', alignment: 'center', colSpan: 6 },
                    {},
                    {},
                    {},
                    {},
                    {}
                  ],
                  [
                    { text: vm.$t('crop_price_config.major_marketing_item'), style: 'th1', alignment: 'center', colSpan: 6 },
                    {},
                    {},
                    {},
                    {},
                    {}
                  ]
                ]
          },
          layout: {
            hLineWidth: function (i, node) {
              return 0
            },
            vLineWidth: function (i, node) {
              return 0
            }
          }
        })
        // pdfContent.push({ text: vm.$t('crop_price_config.major_marketing_item'), style: 'th2', alignment: 'center' })
        pdfContent.push({ text: '', style: 'fertilizer' })
        const detailsData = [
          [
            { text: vm.$t('globalTrans.sl_no'), style: 'th', alignment: 'center' },
            { text: vm.$t('crop_price_config.commodity'), style: 'th', alignment: 'center' },
            { text: vm.$t('crop_price_config.quantity'), style: 'th', alignment: 'center' },
            { text: vm.$t('crop_price_config.unit'), style: 'th', alignment: 'center' }
          ]
        ]
        market.marketing_items.map((item, index) => {
          detailsData.push(
            [
              { text: vm.$n(index + 1), style: 'td', alignment: 'center' },
              { text: (i18n.locale === 'bn') ? vm.$store.state.agriMarketing.commonObj.commodityNameList.find(doc => doc.value === parseInt(item.commodity)).text_bn : vm.$store.state.agriMarketing.commonObj.commodityNameList.find(doc => doc.value === parseInt(item.commodity)).text_en, style: 'td', alignment: 'center' },
              { text: vm.$n(item.quantity), style: 'td', alignment: 'center' },
              { text: (i18n.locale === 'bn') ? vm.$store.state.agriMarketing.commonObj.measurementUnitList.find(doc => doc.value === parseInt(item.unit)).text_bn : vm.$store.state.agriMarketing.commonObj.measurementUnitList.find(doc => doc.value === parseInt(item.unit)).text_en, style: 'td', alignment: 'center' }
            ]
          )
        })
        pdfContent.push({
          table: {
            headerRows: 1,
            widths: ['10%', '35%', '20%', '30%'],
            // widths: ['20%', '30%'],
            body: detailsData
          }
        })
        pdfContent.push({
            table: {
                style: 'tableData',
                headerRows: 0,
                widths: ['20%', '2%', '28%', '20%', '2%', '28%'],
                body: [
                    [
                    { text: '', style: 'fertilizer', alignment: 'center', colSpan: 6 },
                    {},
                    {},
                    {},
                    {},
                    {}
                  ],
                  [
                    { text: vm.$t('crop_price_config.farmer_and_share_product'), style: 'th1', alignment: 'center', colSpan: 6 },
                    {},
                    {},
                    {},
                    {},
                    {}
                  ],
                  [
                    { text: vm.$t('crop_price_config.FarmerShare'), style: 'th', alignment: 'left' },
                    { text: ':', style: 'th', alignment: 'center' },
                    { text: vm.$n(market.farmer_share, { useGrouping: false }) + '%', style: 'td', alignment: 'left' },
                    { text: vm.$t('crop_price_config.TraderShare'), style: 'th', alignment: 'left' },
                    { text: ':', style: 'th', alignment: 'center' },
                    { text: (vm.$n(market.trader_share, { useGrouping: false })) + '%', style: 'td', alignment: 'left' }
                    ],
                  [
                    { text: '', style: 'fertilizer', alignment: 'center', colSpan: 6 },
                    {},
                    {},
                    {},
                    {},
                    {}
                  ],
                  [
                    { text: vm.$t('crop_price_config.Lease_value'), style: 'th1', alignment: 'center', colSpan: 6 },
                    {},
                    {},
                    {},
                    {},
                    {}
                  ]
                ]
            },
            layout: {
            hLineWidth: function (i, node) {
                return 0
            },
            vLineWidth: function (i, node) {
                return 0
            }
            }
        })
        pdfContent.push({ text: '', style: 'fertilizer' })
        const leaseData = []
        market.lease_value.map((item, index) => {
           leaseData.push(
            [
              { text: (i18n.locale === 'en') ? vm.$store.state.agriMarketing.commonObj.leaseYearList.find(doc => doc.value === parseInt(item.lease_year_id)).text_en : vm.$store.state.agriMarketing.commonObj.leaseYearList.find(doc => doc.value === parseInt(item.lease_year_id)).text_bn, style: 'th', alignment: 'center' },
              { text: ':', style: 'td', alignment: 'center' },
            //   {},
              { text: vm.$n(item.value), style: 'td', alignment: 'center' }
            ]
          )
        })
        pdfContent.push({
          table: {
            headerRows: 1,
            widths: ['20%', '5%', '50%'],
            body: leaseData
          }
        })
        pdfContent.push({
            table: {
                style: 'tableData',
                headerRows: 0,
                widths: ['30%', '2%', '28%', '20%', '2%', '18%'],
                body: [
                    [
                    { text: '', style: 'fertilizer', alignment: 'center', colSpan: 6 },
                    {},
                    {},
                    {},
                    {},
                    {}
                  ],
                  [
                    { text: vm.$t('crop_price_config.designation_of_product'), style: 'th1', alignment: 'center', colSpan: 6 },
                    {},
                    {},
                    {},
                    {},
                    {}
                  ],
                  [
                    { text: vm.$t('crop_price_config.product_destination'), style: 'th', alignment: 'left' },
                    { text: ':', style: 'th', alignment: 'center' },
                    { text: vm.$i18n.locale === 'en' ? market.destination : market.destination_bn, style: 'td', alignment: 'left' },
                    { text: vm.$t('crop_price_config.other_product_destination'), style: 'th', alignment: 'left' },
                    { text: ':', style: 'th', alignment: 'center' },
                    { text: market.other_product_destination, style: 'td', alignment: 'left' }
                  ],
                  [
                    { text: vm.$t('crop_price_config.vehicles_carrying_produces'), style: 'th1', alignment: 'center', colSpan: 6 },
                    {},
                    {},
                    {},
                    {},
                    {}
                  ],
                  [
                    { text: vm.$t('crop_price_config.vehicle'), style: 'th', alignment: 'left' },
                    { text: ':', style: 'th', alignment: 'center' },
                    { text: vm.$i18n.locale === 'en' ? market.vehicle : market.vehicle_bn, style: 'td', alignment: 'left', colSpan: 4 },
                    {},
                    {},
                    {}
                  ],
                  [
                    { text: vm.$t('crop_price_config.average_distrance'), style: 'th1', alignment: 'center', colSpan: 6 },
                    {},
                    {},
                    {},
                    {},
                    {}
                  ],
                  [
                    { text: vm.$t('crop_price_config.avg_distance_km'), style: 'th', alignment: 'left' },
                    { text: ':', style: 'th', alignment: 'center' },
                    { text: vm.$n(market.avg_distance), style: 'td', alignment: 'left', colSpan: 4 },
                    {},
                    {},
                    {}
                  ],
                  [
                    { text: vm.$t('crop_price_config.data_collection_year'), style: 'th1', alignment: 'center', colSpan: 6 },
                    {},
                    {},
                    {},
                    {},
                    {}
                  ],
                  [
                    { text: vm.$t('crop_price_config.data_collection_year'), style: 'th', alignment: 'left' },
                    { text: ':', style: 'th', alignment: 'center' },
                    { text: vm.$n(market.data_collection_year, { useGrouping: false }), style: 'td', alignment: 'left', colSpan: 4 },
                    {},
                    {},
                    {}
                  ],
                  [
                    { text: vm.$t('crop_price_config.chairmen_market_commities'), style: 'th1', alignment: 'center', colSpan: 6 },
                    {},
                    {},
                    {},
                    {},
                    {}
                  ],
                  [
                    { text: vm.$t('crop_price_config.tel_mobile_number'), style: 'th', alignment: 'left' },
                    { text: ':', style: 'th', alignment: 'center' },
                    { text: market.mobile_market_committee, style: 'td', alignment: 'left', colSpan: 3 },
                    {},
                    {},
                    {}
                  ],
                  [
                    { text: vm.$t('crop_price_config.market_google_map'), style: 'th1', alignment: 'center', colSpan: 6 },
                    {},
                    {},
                    {},
                    {},
                    {}
                  ],
                  [
                    { text: vm.$t('crop_price_config.Latitude'), style: 'th', alignment: 'left' },
                    { text: ':', style: 'th', alignment: 'center' },
                    { text: market.latitude, style: 'td', alignment: 'left' },
                    { text: vm.$t('crop_price_config.Longitude'), style: 'th', alignment: 'left' },
                    { text: ':', style: 'th', alignment: 'center' },
                    { text: market.longitude, style: 'td', alignment: 'left' }
                  ]
                ]
            },
            layout: {
            hLineWidth: function (i, node) {
                return 0
            },
            vLineWidth: function (i, node) {
                return 0
            }
            }
        })
      const waterMarkText = i18n.locale === 'bn' ? 'কৃষি মন্ত্রণালয়' : 'Ministry of Agriculture'
      var docDefinition = {
        content: pdfContent,
        pageSize: 'A4',
        pageOrientation: 'Portrait',
        watermark: { text: waterMarkText, color: 'blue', opacity: 0.1, bold: true, italics: false },
        styles: {
          th: {
            fontSize: 11,
            bold: true,
            margin: [3, 3, 3, 3]
          },
          td: {
            fontSize: 10,
            margin: [3, 3, 3, 3]
          },
          header: {
            fontSize: 18,
            bold: true,
            margin: [0, -10, 0, -20]
        },
        org: {
            fontSize: 13,
            bold: true,
            margin: [0, -25, 10, 5]
        },
        address: {
            fontSize: 11,
            margin: [0, 0, 0, 5]
        },
        hh: {
            fontSize: 14,
            bold: true,
            margin: [10, 10, 25, 20]
        },
          header2: {
            fontSize: 10,
            margin: [0, 10, 0, 10]
          },
          fertilizer: {
            margin: [0, 7, 0, 7]
          },
          headerPort1: {
            fontSize: 10,
            margin: [0, 20, 0, 5]
          },
          headerPort: {
            fontSize: 10,
            margin: [0, 4, 0, 15]
          },
          header3: {
            fontSize: 9,
            margin: [0, 0, 0, 0]
          },
          th1: {
              fillColor: '#C6C2C1',
              fontSize: 11,
              bold: true,
              margin: [3, 3, 3, 3]
          },
          th2: {
              margin: [3, 3, 3, 80]
          },
          tableSubHead: {
            margin: [0, 5, 0, 15]
          },
          krishi: {
            margin: [0, 1, 0, 15],
            alignment: 'center'
          },
          report_type: {
            fontSize: 9,
            margin: [0, 2, 0, 15]
          }
        }
      }
      pdfMake.createPdf(docDefinition, null, null, null).download('market_directory')
    } catch (error) {
      if (error) {}
    }
    Store.commit('mutateCommonProperties', {
      loading: false
    })
}
export default {
  exportPdfDetails
}
