<template>
  <b-container fluid>
      <b-row>
        <b-col lg="12" sm="12">
          <iq-card>
            <template>
              <b-overlay :show="loading">
                <!-- <b-row>
                    <b-col>
                        <list-report-head :base-url="trainingElearningServiceBaseUrl" uri="/config/report-head/detail" :org-id="12">
                            <div class="titleDiv">
                              <span class="text-center text-dark reportTitle" style="font-weight:bold;">{{ $t('elearning_iabm.training_applications') + ' ' + this.$t('globalTrans.details') }}</span><br>
                            </div>
                        </list-report-head>
                    </b-col>
                </b-row> -->
                <b-row>
                  <b-col lg="12" sm="12">
                    <b-table-simple bordered>
                      <tr>
                        <th style="color: green!important;font-size: 15px!important;"  class="text-center bg-light font-weight-bold" colspan="6">{{ $t('crop_price_config.name_foundation_location_market') }}</th>
                      </tr>
                      <tr>
                        <th style="width: 15%"  class="text-right">{{ $t('crop_price_config.market_name') }}</th>
                        <th style="width: 5%" class="text-center">:</th>
                        <td style="width: 30%">{{ $i18n.locale === 'bn' ? formData.market_name_bn : formData.market_name }}</td>
                        <th style="width: 15%"  class="text-right">{{ $t('crop_price_config.foundation_year') }}</th>
                        <th style="width: 5%" class="text-center">:</th>
                        <td style="width: 30%">{{ $n(formData.foundation_year, { useGrouping: false }) }}</td>
                        <!-- <th style="width: 15%; vertical-align:middle"  class="text-center" rowspan="4">{{ $t('globalTrans.photo') }}</th>
                        <td style="width: 30%" rowspan="4" colspan="2"><div class=""><img :src="agriMarketingServiceBaseUrl + 'storage/' + formData.image" class="rounded img-fluid" style="max-width: 100%; height: auto; border-radius: 0px !important;"></div></td> -->
                      </tr>
                      <tr>
                        <th style="width: 15%; vertical-align:middle"  class="text-center" rowspan="4">{{ $t('globalTrans.photo') }}</th>
                        <td style="width: 30%" rowspan="4" colspan="2"><div class="" v-if="formData.image">
                            <img :src="agriMarketingServiceBaseUrl + 'storage/' + formData.image" class="rounded img-fluid" style="max-width: 100%; height: auto; border-radius: 0px !important;">
                            </div></td>
                        <th style="width: 15%"  class="text-right">{{ $t('crop_price_config.Village') }}</th>
                        <th style="width: 5%" class="text-center">:</th>
                        <td style="width: 30%">{{ $i18n.locale === 'bn' ? formData.village : formData.village }}</td>
                      </tr>
                      <tr>
                        <th style="width: 15%"  class="text-right">{{ $t('crop_price_config.PostOffice') }}</th>
                        <th style="width: 5%" class="text-center">:</th>
                        <td style="width: 30%">{{ $i18n.locale === 'bn' ? formData.post_office : formData.post_office }}</td>
                      </tr>
                      <tr>
                        <th style="width: 15%"  class="text-right">{{ $t('crop_price_config.p_t_u') }}</th>
                        <th style="width: 5%" class="text-center">:</th>
                        <td style="width: 30%">{{ $i18n.locale === 'bn' ? formData.union : formData.union }}</td>
                      </tr>
                      <tr>
                        <th style="width: 15%"  class="text-right">{{ $t('crop_price_config.MarketType') }}</th>
                        <th style="width: 5%" class="text-center">:</th>
                        <td style="width: 30%">{{ $i18n.locale === 'bn' ? formData.market_type_name_bn : formData.market_type_name }}</td>
                      </tr>
                      <tr>
                        <th style="color: green!important;font-size: 15px!important;"  class="text-center bg-light font-weight-bold" colspan="6">{{ $t('crop_price_config.dicrtict_division') }}</th>
                      </tr>
                      <tr>
                        <th style="width: 15%"  class="text-right" >{{ $t('globalTrans.division') }}</th>
                        <th class="text-center" style="width: 5%">:</th>
                        <td style="width: 30%" class="text-left">{{ $i18n.locale === 'en' ? formData.division_name : formData.division_name_bn }}</td>
                        <th style="width: 15%" class="text-right" >{{ $t('globalTrans.district') }}</th>
                        <th class="text-center" style="width: 5%">:</th>
                        <td style="width: 30%" class="text-left">{{ $i18n.locale === 'en' ? formData.district_name : formData.district_name_bn }}</td>
                      </tr>
                      <tr>
                        <th style="width: 15%"  class="text-right">{{ $t('globalTrans.upazila') }}</th>
                        <th class="text-center" style="width: 5%">:</th>
                        <td style="width: 30%" class="text-left">{{ $i18n.locale === 'en' ? formData.upazilla_name : formData.upazilla_name_bn }}</td>
                      </tr>
                      <tr>
                        <th style="color: green!important;font-size: 15px!important;"  class="text-center bg-light font-weight-bold" colspan="6">{{ $t('crop_price_config.market_representative_information') }}</th>
                      </tr>
                      <tr>
                        <th style="width: 15%"  class="text-right">{{ $t('crop_price_config.name') }}</th>
                        <th style="width: 5%" class="text-center">:</th>
                        <td style="width: 30%" class="text-left">{{ $i18n.locale === 'bn' ? formData.market_representative_name : formData.market_representative_name }}</td>
                        <th style="width: 15%"  class="text-right">{{ $t('crop_price_config.tel_mobile_number') }}</th>
                        <th class="text-center" style="width: 5%">:</th>
                        <td style="width: 30%">{{ $i18n.locale === 'bn' ? '০' : '0' }}{{ $n(formData.market_representative_mobile, { useGrouping: false }) }}</td>
                      </tr>
                      <tr>
                        <th style="color: green!important;font-size: 15px!important;"  class="text-center bg-light font-weight-bold" colspan="6">{{ $t('crop_price_config.total_market_area') }}</th>
                      </tr>
                      <tr>
                        <th style="width: 15%"  class="text-right">{{ $t('crop_price_config.GovtCovered') }}</th>
                        <th style="width: 5%" class="text-center">:</th>
                        <td style="width: 30%">{{ $n(formData.govt_covered, { useGrouping: false }) }}</td>
                        <th style="width: 15%"  class="text-right">{{ $t('crop_price_config.GovtOpen') }}</th>
                        <th style="width: 5%" class="text-center">:</th>
                        <td style="width: 30%">{{ $n(formData.govt_open, { useGrouping: false }) }}</td>
                      </tr>
                      <tr>
                        <th style="width: 15%"  class="text-right">{{ $t('crop_price_config.PrivateCovered') }}</th>
                        <th style="width: 5%" class="text-center">:</th>
                        <td style="width: 30%">{{ $n(formData.private_covered, { useGrouping: false }) }}</td>
                        <th style="width: 15%"  class="text-right">{{ $t('crop_price_config.PrivateOpen') }}</th>
                        <th style="width: 5%" class="text-center">:</th>
                        <td style="width: 30%">{{ $n(formData.private_open, { useGrouping: false }) }}</td>
                      </tr>
                      <tr>
                        <th style="color: green!important;font-size: 15px!important;"  class="text-center bg-light font-weight-bold" colspan="6">{{ $t('crop_price_config.number_of_stall_shed') }}</th>
                      </tr>
                      <tr>
                        <th style="width: 15%"  class="text-right">{{ $t('crop_price_config.ShedNo') }}</th>
                        <th style="width: 5%" class="text-center">:</th>
                        <td style="width: 30%">{{ $n(formData.shed_no, { useGrouping: false }) }}</td>
                        <th style="width: 15%"  class="text-right">{{ $t('crop_price_config.ShedArea') }}</th>
                        <th style="width: 5%" class="text-center">:</th>
                        <td style="width: 30%">{{ $n(formData.shed_area, { useGrouping: false }) }}</td>
                      </tr>
                      <tr>
                        <th style="width: 15%"  class="text-right">{{ $t('crop_price_config.StallCountAgr') }}</th>
                        <th style="width: 5%" class="text-center">:</th>
                        <td style="width: 30%">{{ $n(formData.stall_no_agri, { useGrouping: false }) }}</td>
                        <th style="width: 15%"  class="text-right">{{ $t('crop_price_config.StallCountNonAgr') }}</th>
                        <th style="width: 5%" class="text-center">:</th>
                        <td style="width: 30%">{{ $n(formData.stall_no_nonagri, { useGrouping: false }) }}</td>
                      </tr>
                      <tr>
                        <th style="color: green!important;font-size: 15px!important;"  class="text-center bg-light font-weight-bold" colspan="6">{{ $t('crop_price_config.hatt_day_and_time') }}</th>
                      </tr>
                      <tr>
                        <th style="width: 15%"  class="text-right">{{ $t('crop_price_config.HatDays') }}</th>
                        <th style="width: 5%" class="text-center">:</th>
                        <td style="width: 30%">{{ $i18n.locale === 'bn' ? formData.hat_day_bn : formData.hat_day }}</td>
                        <th style="width: 15%"  class="text-right">{{ $t('crop_price_config.market_time_from') }}</th>
                        <th style="width: 5%" class="text-center">:</th>
                        <td style="width: 30%">{{ formData.market_time_from  | time12Formate }}</td>
                      </tr>
                      <tr>
                        <th style="width: 15%"  class="text-right">{{ $t('crop_price_config.market_time_to') }}</th>
                        <th style="width: 5%" class="text-center">:</th>
                        <td style="width: 30%">{{ formData.market_time_to  | time12Formate }}</td>
                      </tr>
                      <tr>
                        <th style="color: green!important;font-size: 15px!important;"  class="text-center bg-light font-weight-bold" colspan="6">{{ $t('crop_price_config.avalibility_facilities') }}</th>
                      </tr>
                      <tr>
                        <th style="width: 15%"  class="text-right">{{ $t('crop_price_config.infrastructure') }}</th>
                        <th style="width: 5%" class="text-center">:</th>
                        <td style="width: 30%">{{ $i18n.locale === 'bn' ? formData.infa_bn : formData.infa }}</td>
                        <th style="width: 15%"  class="text-right">{{ $t('crop_price_config.communcication_Linkage') }}</th>
                        <th style="width: 5%" class="text-center">:</th>
                        <td style="width: 30%">{{ $i18n.locale === 'bn' ? formData.communication_bn : formData.communication }}</td>
                      </tr>
                      <tr>
                        <th style="color: green!important;font-size: 15px!important;"  class="text-center bg-light font-weight-bold" colspan="6">{{ $t('crop_price_config.buyer_seller') }}</th>
                      </tr>
                      <tr>
                        <th style="width: 15%"  class="text-right">{{ $t('crop_price_config.BuyerSellecount') }}</th>
                        <th style="width: 5%" class="text-center">:</th>
                        <td style="width: 30%">{{ $n(formData.number_of_buyers, { useGrouping: false }) }}</td>
                        <th style="width: 15%"  class="text-right">{{ $t('crop_price_config.ProduceAssemblage') }}</th>
                        <th style="width: 5%" class="text-center">:</th>
                        <td style="width: 30%">{{ $n(formData.number_of_sellers, { useGrouping: false }) }}</td>
                      </tr>
                      <tr>
                        <th style="color: green!important;font-size: 15px!important;"  class="text-center bg-light font-weight-bold" colspan="6">{{ $t('crop_price_config.major_marketing_item') }}</th>
                      </tr>
                      <tr>
                        <td colspan="6">
                            <b-table-simple bordered>
                                <b-tr class="bg-primary">
                                    <b-th style="width:25%">{{ $t('globalTrans.sl_no') }}</b-th>
                                    <b-th style="width:25%">{{ $t('crop_price_config.commodity') }}</b-th>
                                    <b-th style="width:25%">{{ $t('crop_price_config.quantity') }}</b-th>
                                    <b-th style="width:25%">{{ $t('crop_price_config.unit') }}</b-th>
                                </b-tr>
                                <b-tr v-for="(item,index) in formData.marketing_items" :key="index">
                                    <b-td style="width:10%">{{ $n(index + 1) }}</b-td>
                                    <b-td style="width:30%">
                                      <slot v-if="item.commodity > 0">
                                        {{ ($i18n.locale=='en') ? $store.state.agriMarketing.commonObj.commodityNameList.find(doc => doc.value === parseInt(item.commodity)).text_en : $store.state.agriMarketing.commonObj.commodityNameList.find(doc => doc.value === parseInt(item.commodity)).text_bn }}
                                      </slot>
                                    </b-td>
                                    <b-td style="width:30%">{{ $n(item.quantity) }}</b-td>
                                    <b-td style="width:30%">
                                      <slot v-if="item.unit > 0">
                                        {{ ($i18n.locale=='en') ? $store.state.agriMarketing.commonObj.measurementUnitList.find(doc => doc.value === parseInt(item.unit)).text_en : $store.state.agriMarketing.commonObj.measurementUnitList.find(doc => doc.value === parseInt(item.unit)).text_bn }}
                                      </slot>
                                    </b-td>
                                </b-tr>
                            </b-table-simple>
                        </td>
                      </tr>
                      <tr>
                        <th style="color: green!important;font-size: 15px!important;"  class="text-center bg-light font-weight-bold" colspan="6">{{ $t('crop_price_config.farmer_and_share_product') }}</th>
                      </tr>
                      <tr>
                        <th style="width: 15%"  class="text-right">{{ $t('crop_price_config.FarmerShare') }}</th>
                        <th style="width: 5%" class="text-center">:</th>
                        <td style="width: 30%">{{ $n(formData.farmer_share)}}%</td>
                        <th style="width: 15%"  class="text-right">{{ $t('crop_price_config.TraderShare') }}</th>
                        <th style="width: 5%" class="text-center">:</th>
                        <td style="width: 30%">{{ $n(formData.trader_share)}}%</td>
                      </tr>
                      <tr>
                        <th style="color: green!important;font-size: 15px!important;"  class="text-center bg-light font-weight-bold" colspan="6">{{ $t('crop_price_config.Lease_value') }}</th>
                      </tr>
                      <tr>
                          <td colspan="2"></td>
                            <td colspan="3">
                                <table style="width:100%">
                                    <tr v-for="(item,index) in formData.lease_value" :key="index" style="text-align:center">
                                        <th class="text-right">{{ ($i18n.locale=='en') ? $store.state.agriMarketing.commonObj.leaseYearList.find(doc => doc.value === parseInt(item.lease_year_id)).text_en : $store.state.agriMarketing.commonObj.leaseYearList.find(doc => doc.value === parseInt(item.lease_year_id)).text_bn }}</th>
                                        <td style="width:10%">:</td>
                                        <td>{{ $n(item.value)}}</td>
                                    </tr>
                                </table>
                            </td>
                        </tr>
                      <tr>
                        <th style="color: green!important;font-size: 15px!important;"  class="text-center bg-light font-weight-bold" colspan="6">{{ $t('crop_price_config.designation_of_product') }}</th>
                      </tr>
                      <tr>
                        <th style="width: 15%"  class="text-right">{{ $t('crop_price_config.product_destination') }}</th>
                        <th style="width: 5%" class="text-center">:</th>
                        <td style="width: 30%">{{ $i18n.locale === 'bn' ? formData.destination_bn : formData.destination }}</td>
                        <th style="width: 15%"  class="text-right">{{ $t('crop_price_config.other_product_destination') }}</th>
                        <th style="width: 5%" class="text-center">:</th>
                        <td style="width: 30%">{{ formData.other_product_destination }}</td>
                      </tr>
                      <tr>
                        <th style="color: green!important;font-size: 15px!important;"  class="text-center bg-light font-weight-bold" colspan="6">{{ $t('crop_price_config.vehicles_carrying_produces') }}</th>
                      </tr>
                      <tr>
                        <th style="width: 15%"  class="text-right">{{ $t('crop_price_config.vehicle') }}</th>
                        <th style="width: 5%" class="text-center">:</th>
                        <td style="width: 30%">{{ $i18n.locale === 'bn' ? formData.vehicle_bn : formData.vehicle }}</td>
                      </tr>
                      <tr>
                        <th style="color: green!important;font-size: 15px!important;"  class="text-center bg-light font-weight-bold" colspan="6">{{ $t('crop_price_config.average_distrance') }}</th>
                      </tr>
                      <tr>
                        <th style="width: 15%"  class="text-right">{{ $t('crop_price_config.avg_distance_km') }}</th>
                        <th style="width: 5%" class="text-center">:</th>
                        <td style="width: 30%">{{ $n(formData.avg_distance) }}</td>
                      </tr>
                      <tr>
                        <th style="color: green!important;font-size: 15px!important;"  class="text-center bg-light font-weight-bold" colspan="6">{{ $t('crop_price_config.data_collection_year') }}</th>
                      </tr>
                      <tr>
                        <th style="width: 15%"  class="text-right">{{ $t('crop_price_config.data_collection_year') }}</th>
                        <th style="width: 5%" class="text-center">:</th>
                        <td style="width: 30%">{{ $n(formData.data_collection_year, { useGrouping: false }) }}</td>
                      </tr>
                      <tr>
                        <th style="color: green!important;font-size: 15px!important;"  class="text-center bg-light font-weight-bold" colspan="6">{{ $t('crop_price_config.chairmen_market_commities') }}</th>
                      </tr>
                      <tr>
                        <th style="width: 15%"  class="text-right">{{ $t('crop_price_config.tel_mobile_number') }}</th>
                        <th style="width: 5%" class="text-center">:</th>
                        <td style="width: 30%">{{ formData.mobile_market_committee }}</td>
                      </tr>
                      <tr>
                        <th style="color: green!important;font-size: 15px!important;"  class="text-center bg-light font-weight-bold" colspan="6">{{ $t('crop_price_config.market_google_map') }}</th>
                      </tr>
                      <tr>
                        <th style="width: 15%"  class="text-right">{{ $t('crop_price_config.Latitude') }}</th>
                        <th style="width: 5%" class="text-center">:</th>
                        <td style="width: 30%">{{ formData.latitude }}</td>
                        <th style="width: 15%"  class="text-right">{{ $t('crop_price_config.Longitude') }}</th>
                        <th style="width: 5%" class="text-center">:</th>
                        <td style="width: 30%">{{ formData.longitude }}</td>
                      </tr>
                    </b-table-simple>
                  </b-col>
                </b-row>
              </b-overlay>
            </template>
          </iq-card>
        </b-col>
      </b-row>
    </b-container>
</template>
<script>
import ExportPdf from './export_pdf_details'
import RestApi, { trainingElearningServiceBaseUrl, agriMarketingServiceBaseUrl } from '@/config/api_config'
import { MasterMarketPdfImage } from '../../api/routes'
// import ListReportHead from '@/components/custom/ListReportHead.vue'

export default {
    components: {
    //   ListReportHead
    },
    name: 'FormLayout',
    props: ['item'],
    created () {
      this.formData = this.item
      this.pdfImage(this.formData.id)
    },
    data () {
        return {
            formData: {},
            slOffset: 1,
            agriMarketingServiceBaseUrl: agriMarketingServiceBaseUrl
        }
    },
    computed: {
      loading () {
        return this.$store.state.commonObj.loading
      },
      accommodationTypeList: function () {
        return [
          { text: this.currentLocale === 'en' ? 'With Accommodation' : 'থাকার ব্যবস্থা সহ', text_en: 'With Accommodation', text_bn: 'থাকার ব্যবস্থা সহ', value: 1 },
          { text: this.currentLocale === 'en' ? 'Without Accommodation' : 'থাকার ব্যবস্থা ছাড়া', text_en: 'Without Accommodation', text_bn: 'থাকার ব্যবস্থা ছাড়া', value: 2 }
        ]
      }
    },
    methods: {
      pdfImage (id) {
        RestApi.getData(agriMarketingServiceBaseUrl, MasterMarketPdfImage + '/' + id).then(response => {
          if (response.success) {
            this.formData.pdf_image = response.data.pdf_image
          } else {}
        })
      },
      pdfExport () {
        const reportTitle = this.$t('crop_price_config.market') + ' ' + this.$t('globalTrans.details')
        ExportPdf.exportPdfDetails(trainingElearningServiceBaseUrl, '/config/report-head/detail', 12, reportTitle, this.formData, this, this.formData.pdf_image)
      }
    }
}
</script>
<style>
  .hidden_header {
    display: none
  }
  .card-border {
    border: 1px solid #b9bdc1;
    box-shadow: 1px 1px 6px -1px grey;
    background-color: #dee2e6;
  }
  [dir=ltr][mode=light] .card-body {
    flex: 1 1 auto;
    min-height: 1px;
    padding: 10px;
  }
  .reportTitle {
        font-weight: bold;
        border: 1px solid;
        padding: 8px 15px 8px 15px;
        border-radius: 11px;
  }
  .titleDiv {
    margin-top: 2rem;
    font-size: 18px;
    margin-bottom: 20px;
  }
  .report-name{
    font-weight: bold !important;
    text-transform: uppercase;
  }
  .my-btn{
    padding: 2px !important;
  }
</style>
