import i18n from '@/i18n'
import Store from '@/store'
import ReportHeading from '@/Utils/report-head'
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFontsBn from 'pdfmake/build/vfs_fonts_bn'
import pdfFontsEn from 'pdfmake/build/vfs_fonts_en'
// import { dateFormat } from '@/Utils/fliter'

const exportPdfDetails = async (baseUrl, uri, orgId, reportTitle, data, vm) => {
    try {
      Store.commit('mutateCommonProperties', {
        loading: true
      })
      if (i18n.locale === 'bn') {
        pdfMake.vfs = pdfFontsBn.pdfMake.vfs
      } else {
          pdfMake.vfs = pdfFontsEn.pdfMake.vfs
      }
      const reportHeadData = await ReportHeading.getReportHead(baseUrl, uri, orgId)
      const pdfContent = [
          {
              columns: reportHeadData.reportHeadColumn, style: 'main_head'
          },
          { text: reportHeadData.address, style: 'address', alignment: 'center' }
        ]
        pdfContent.push({ text: reportTitle, style: 'header2', alignment: 'center', decoration: 'underline' })
        pdfContent.push({ text: '', style: 'fertilizer' })
        const allRows = [
          [
            { text: vm.$t('globalTrans.sl_no'), style: 'th', alignment: 'center', bold: true },
            { text: vm.$t('globalTrans.division'), style: 'th', alignment: 'center', bold: true },
            { text: vm.$t('globalTrans.district'), style: 'th', alignment: 'center', bold: true },
            { text: vm.$t('globalTrans.upazila'), style: 'th', alignment: 'center', bold: true },
            { text: vm.$t('crop_price_config.market_name'), style: 'th', alignment: 'center', bold: true },
            { text: vm.$t('crop_price_config.Village'), style: 'th', alignment: 'center', bold: true },
            { text: vm.$t('crop_price_config.PostOffice'), style: 'th', alignment: 'center', bold: true },
            { text: vm.$t('crop_price_config.p_t_u'), style: 'th', alignment: 'center', bold: true }
          ]
        ]
        data.forEach((info, index) => {
          allRows.push([
            { text: vm.$n(index + 1), alignment: 'center', style: 'td' },
            { text: i18n.locale === 'bn' ? info.division_name_bn : info.division_name, alignment: 'center', style: 'td' },
            { text: i18n.locale === 'bn' ? info.district_name_bn : info.district_name, alignment: 'center', style: 'td' },
            { text: i18n.locale === 'bn' ? info.upazilla_name_bn : info.upazilla_name, alignment: 'center', style: 'td' },
            { text: i18n.locale === 'bn' ? info.market_name_bn : info.market_name, alignment: 'center', style: 'td' },
            { text: i18n.locale === 'bn' ? info.village : info.village, alignment: 'center', style: 'td' },
            { text: i18n.locale === 'bn' ? info.post_office : info.post_office, alignment: 'center', style: 'td' },
            { text: i18n.locale === 'bn' ? info.union : info.union, alignment: 'center', style: 'td' }
          ])
        })
        pdfContent.push({
          table: {
            headerRows: 1,
            widths: ['7%', '10%', '10%', '12%', '16%', '15%', '15%', '15%'],
            body: allRows
          }
        })
        pdfContent.push({ text: '', style: 'fertilizer' })
        const waterMarkText = i18n.locale === 'bn' ? 'কৃষি মন্ত্রণালয়' : 'Ministry of Agriculture'
        var docDefinition = {
        content: pdfContent,
        pageSize: 'A4',
        pageOrientation: 'Landscape',
        watermark: { text: waterMarkText, color: 'blue', opacity: 0.1, bold: true, italics: false },
        styles: {
          th: {
            fontSize: (i18n.locale === 'bn') ? 10 : 9,
            margin: [3, 3, 3, 3]
          },
          td: {
            fontSize: (i18n.locale === 'bn') ? 10 : 9,
            margin: [3, 3, 3, 3]
          },
          search: {
            fontSize: (i18n.locale === 'bn') ? 10 : 8,
            margin: [3, 3, 3, 3]
          },
          fertilizer: {
            margin: [5, 0, 0, 5]
          },
          header: {
              fontSize: 18,
              bold: true,
              margin: [0, -10, 0, -20]
          },
          org: {
              fontSize: 13,
              bold: true,
              margin: [0, -25, 10, 5]
          },
          address: {
            fontSize: 9,
            margin: [0, -10, 0, 0]
          },
          hh: {
              fontSize: 13,
              bold: true,
              margin: [10, 10, 25, 20]
          },
          header2: {
            fontSize: 14,
            margin: [0, 10, 0, 20]
          },
          headerPort1: {
            fontSize: 10,
            margin: [0, 20, 0, 5]
          },
          headerPort: {
            fontSize: 10,
            margin: [0, 4, 0, 15]
          },
          krishi: {
            margin: [0, -5, 0, 15],
            alignment: 'center'
          },
          header3: {
            fontSize: 9,
            margin: [0, 0, 0, 0]
          },
          tableSubHead: {
            margin: [0, 5, 0, 15]
          }
        }
      }
      pdfMake.createPdf(docDefinition, null, null, null).download('market-directory-list')
    } catch (error) {
      if (error) {}
    }
    Store.commit('mutateCommonProperties', {
      loading: false
    })
}
export default {
  exportPdfDetails
}
