
<template>
    <b-container fluid>
        <iq-card>
            <template v-slot:headerTitle>
                <h4 class="card-title">{{ $t('crop_price_config.market') }}</h4>
            </template>
            <template v-slot:body>
                <b-row align-h="around">
                    <b-col xs="12" sm="12" md="5" lg="5" xl="5">
                        <b-form-group
                            class="row"
                            label-cols-sm="3"
                            :label="$t('globalTrans.division')"
                            label-for="division_id"
                            >
                            <b-form-select
                                plain
                                v-model="search.division_id"
                                :options="divisionList"
                                id="division_id"
                                >
                                <template v-slot:first>
                                <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                            </b-form-select>
                        </b-form-group>
                    </b-col>
                    <b-col xs="12" sm="12" md="5" lg="5" xl="5">
                        <b-form-group
                            class="row"
                            label-cols-sm="3"
                            :label="$t('globalTrans.district')"
                            label-for="district_id"
                            >
                            <b-form-select
                                plain
                                v-model="search.district_id"
                                :options="districtList"
                                id="district_id"
                                >
                                <template v-slot:first>
                                <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                            </b-form-select>
                        </b-form-group>
                    </b-col>
                    <b-col xs="12" sm="12" md="5" lg="5" xl="5">
                        <b-form-group
                            class="row"
                            label-cols-sm="3"
                            :label="$t('globalTrans.upazila')"
                            label-for="upazila_id"
                            >
                            <b-form-select
                                plain
                                v-model="search.upazila_id"
                                :options="upazilaList"
                                id="upazila_id"
                                >
                                <template v-slot:first>
                                <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                            </b-form-select>
                        </b-form-group>
                    </b-col>
                    <b-col xs="12" sm="12" md="5" lg="5" xl="5">
                        <b-form-group
                            class="row"
                            label-cols-sm="3"
                            :label="$t('crop_price_config.market_name')"
                            label-for="market_name"
                            >
                            <b-form-input
                                plain
                                v-model="search.market_name"
                                id="market_name"
                                >
                            </b-form-input>
                        </b-form-group>
                    </b-col>
                    <b-col xs="12" sm="12" md="11" class="text-right">
                      <b-button type="button" variant="primary" @click="searchData">{{ $t('globalTrans.search') }}</b-button>
                    </b-col>
                </b-row>
            </template>
        </iq-card>
        <b-row>
            <b-col md="12">
                <iq-card>
                    <template v-slot:headerTitle>
                        <h4 class="card-title">{{ $t('crop_price_config.market_list') }}</h4>
                    </template>
                    <template v-slot:headerAction>
                      <!-- <b-button variant="primary" v-b-modal.modal-form @click="resetId">
                          {{ $t('globalTrans.add_new') }}
                      </b-button> -->
                      <router-link class="btn btn_add_new mr-2" :to="{ path: '/agri-marketing/crop-price-info/configuration/market-directory' }">
                        <i class="fas fa-plus"></i>{{ $t('globalTrans.add_new') }}
                      </router-link>
                      <b-button class="btn_add_new mr-2" @click="pdfExport">
                        <i class="far fa-file-pdf"></i>{{  $t('globalTrans.export_pdf') }}
                      </b-button>
                      <export-excel
                        class="btn btn_add_new"
                        :data="excelData"
                        :title="headerValue"
                        name="market-directory-list.xls">
                        <i class="far fa-file-excel"></i>{{ $t('globalTrans.export_excel') }}
                      </export-excel>
                    </template>
                    <template v-slot:body>
                        <b-overlay :show="loadingState">
                            <b-row>
                                <b-col md="12" class="table-responsive steaky-header">
                                    <b-table head-variant="primary" :sticky-header="stickyHeader" :emptyText="$t('globalTrans.noDataFound')" show-empty bordered hover :items="listData" :fields="columns" aria-hidden="loading | listReload ? 'true' : null">
                                        <template v-slot:cell(index)="data">
                                            {{ $n(data.index + pagination.slOffset) }}
                                        </template>
                                        <template v-slot:cell(status)="data">
                                            <span class="badge badge-success" v-if="data.item.status === 1">{{$t('globalTrans.active')}}</span>
                                            <span class="badge badge-danger" v-if="data.item.status === 2">{{$t('globalTrans.inactive')}}</span>
                                        </template>
                                        <template v-slot:cell(action)="data">
                                            <a href="javascript:" class="btn_table_action table_action_view" title="View Details" v-b-modal.modal-5 variant=" iq-bg-success" size="sm" @click="details(data.item)"><i class="fas fa-eye"></i></a>
                                          <router-link class="btn_table_action table_action_edit" title="Edit" :to="{ path: '/agri-marketing/crop-price-info/configuration/market-directory', query: { id: data.item.id, latitude: data.item.latitude, longitude: data.item.longitude }}" size="sm">
                                            <i class="ri-ball-pen-fill"></i>
                                          </router-link>
                                            <a href="javascript:" class="btn_table_action table_action_status" title="Click to Deactive" v-if="data.item.status === 1" @click="remove(data.item)"><i class="fas fa-toggle-on"></i></a>
                                            <a href="javascript:" class="btn_table_action table_action_toggle" title="Click to Active" v-if="data.item.status === 2" @click="remove(data.item)"><i class="fa fa-toggle-off"></i></a>
                                        </template>
                                    </b-table>
                                    <b-pagination
                                        align="center"
                                        v-model="pagination.currentPage"
                                        :per-page="pagination.perPage"
                                        :total-rows="pagination.totalRows"
                                        @input="searchData"
                                        />
                                </b-col>
                            </b-row>
                        </b-overlay>
                    </template>
                </iq-card>
            </b-col>
        </b-row>
        <b-modal id="modal-form" size="xl" :title="formTitle" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
            <Form :id="editItemId" :key="editItemId"/>
        </b-modal>
        <b-modal id="modal-5" size="xl" :title="$t('crop_price_config.market') + ' ' + this.$t('globalTrans.details')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
            <b-button @click="pdfExportDetails" class="ml-4 btn-success water-test-pdf-button">
                {{  $t('globalTrans.export_pdf') }}
            </b-button>
            <Details :item="item" :key="editItemId" ref="details"/>
        </b-modal>
    </b-container>
</template>
<script>
import Form from './Form'
import Details from './Details'
import RestApi, { agriMarketingServiceBaseUrl, warehouseServiceBaseUrl } from '@/config/api_config'
import { MasterMarketList, MasterMarketStatus } from '../../api/routes'
import ExportPdf from './export_pdf_list'
import ModalBaseMasterList from '@/mixins/modal-base-master-list'
import flatpickr from 'flatpickr'
import excel from 'vue-excel-export'
import Vue from 'vue'
// import i18n from '@/i18n'
Vue.use(excel)

export default {
  mixins: [ModalBaseMasterList],
  components: {
    Form, Details
  },
  data () {
    return {
        search: {
            division_id: 0,
            district_id: 0,
            upazila_id: 0,
            divisionList: [],
            districtList: [],
            upazillaList: [],
            marketList: [],
            market_name: ''
        },
        stickyHeader: true,
        headerExcelDefault: {
            orgName: null,
            orgNameBn: null,
            address: null,
            address_bn: null
        },
        warehouseServiceBaseUrl: warehouseServiceBaseUrl,
        editItemId: '',
        item: '',
        districtList: [],
        upazilaList: [],
        unionList: [],
        rows: [],
        marketTypeList: [
            { value: 1, text_en: 'Assemble', text_bn: 'একত্রিত করা' },
            { value: 2, text_en: 'Primary', text_bn: 'প্রাথমিক' },
            { value: 3, text_en: 'Retail', text_bn: 'খুচরা' },
            { value: 4, text_en: 'Wholesale', text_bn: 'পাইকারি' },
            { value: 5, text_en: 'Wholesale Cum Retail', text_bn: 'খুচরা বিক্রয়' }
        ],
        hatDayList: [
            { value: 1, text: 'Daily', text_bn: 'প্রতিদিন' },
            { value: 2, text: 'Friday', text_bn: 'শুক্রবার' },
            { value: 3, text: 'Saturday', text_bn: 'শনিবার' },
            { value: 4, text: 'Sunday', text_bn: 'রবিবার' },
            { value: 5, text: 'Monday', text_bn: 'সোমবার' },
            { value: 6, text: 'Tuesday', text_bn: 'মঙ্গলবার' },
            { value: 7, text: 'Wednesday', text_bn: 'বুধবার' },
            { value: 8, text: 'Thursday', text_bn: 'বৃহস্পতিবার' }
        ]
    }
  },
  mounted () {
    this.headerDataExcel()
    flatpickr('#datepicker', {})
    if (this.$store.state.Auth.activeRoleId !== 1) {
      this.search = Object.assign({}, this.search, {
        divisionList: JSON.stringify(this.loggedUserPrivilege.division_id),
        districtList: JSON.stringify(this.loggedUserPrivilege.district_id),
        upazillaList: JSON.stringify(this.loggedUserPrivilege.upazila_id),
        marketList: JSON.stringify(this.loggedUserPrivilege.market_id)
      })
    }
    this.loadData()
  },
  computed: {
    headerValue: function () {
        const headerVal = []
            if (this.$i18n.locale === 'en') {
                headerVal[0] = this.headerExcelDefault.orgName
                headerVal[1] = this.headerExcelDefault.address
                headerVal[2] = this.$t('crop_price_config.market_list')
            } else {
                headerVal[0] = this.headerExcelDefault.orgNameBn
                headerVal[1] = this.headerExcelDefault.address_bn
                headerVal[2] = this.$t('crop_price_config.market_list')
            }
        return headerVal
    },
    formTitle () {
       return (this.editItemId === 0) ? this.$t('crop_price_config.market') + ' ' + this.$t('globalTrans.entry') : this.$t('crop_price_config.market') + ' ' + this.$t('globalTrans.modify')
    },
    divisionList: function () {
        return this.$store.state.agriMarketing.damData.divisionList
    },
    loggedUserPrivilege: function () {
      return this.$store.state.agriMarketing.commonObj.loggedUserPrivilege
    },
    columns () {
      const labels = [
          { label: this.$t('globalTrans.sl_no'), class: 'text-center' },
          { label: this.$t('globalTrans.division'), class: 'text-center', sortable: true },
          { label: this.$t('globalTrans.district'), class: 'text-center', sortable: true },
          { label: this.$t('globalTrans.upazila'), class: 'textcentert', sortable: true },
          { label: this.$t('crop_price_config.market_name'), class: 'text-center', sortable: true },
          { label: this.$t('crop_price_config.Village'), class: 'text-center', sortable: true },
          { label: this.$t('crop_price_config.PostOffice'), class: 'text-center', sortable: true },
          { label: this.$t('crop_price_config.p_t_u'), class: 'text-center', sortable: true },
          { label: this.$t('globalTrans.status'), class: 'text-center' },
          { label: this.$t('globalTrans.action'), class: 'text-center w-15' }
        ]

      let keys = []

      if (this.$i18n.locale === 'bn') {
        keys = [
          { key: 'index' },
          { key: 'division_name_bn' },
          { key: 'district_name_bn' },
          { key: 'upazilla_name_bn' },
          { key: 'market_name_bn' },
          { key: 'village' },
          { key: 'post_office' },
          { key: 'union' },
          { key: 'status' },
          { key: 'action' }
        ]
      } else {
        keys = [
          { key: 'index' },
          { key: 'division_name' },
          { key: 'district_name' },
          { key: 'upazilla_name' },
          { key: 'market_name' },
          { key: 'village' },
          { key: 'post_office' },
          { key: 'union' },
          { key: 'status' },
          { key: 'action' }
        ]
      }

      return labels.map((item, index) => {
          return Object.assign(item, keys[index])
        })
    },
    excelData: function () {
        const listData = this.listData
        var serial = 0
        const marketDirectory = listData.map(keyItem => {
          serial++
            if (this.$i18n.locale === 'en') {
                return {
                  'sl ': serial,
                  'Division ': keyItem.division_name,
                  'District ': keyItem.district_name,
                  'Upazila ': keyItem.upazilla_name,
                  'Market Name': keyItem.market_name,
                  'Village ': keyItem.village,
                  'Post Office': keyItem.post_office,
                  'Pouroshova / Thana / Union': keyItem.union
                }
            } else {
              return {
                'ক্রমিক সংখ্যা': this.$n(serial),
                'বিভাগ ': keyItem.division_name_bn,
                'জেলা ': keyItem.district_name_bn,
                'উপজেলা ': keyItem.upazilla_name_bn,
                'বাজারের নাম': keyItem.market_name_bn,
                'গ্রাম ': keyItem.village,
                'ডাক ঘর': keyItem.post_office,
                'পৌরসভা / থানা / ইউনিয়ন': keyItem.union
              }
            }
        })
        return marketDirectory
      }
  },
  watch: {
    loadingState: function (newVal, oldVal) {
      if (newVal) {
        this.loadData()
      }
    },
    'search.division_id': function (newVal, oldVal) {
        this.districtList = this.getDistrictList(newVal)
    },
    'search.district_id': function (newVal, oldVal) {
    this.upazilaList = this.getUpazilaList(newVal)
    }
  },
  created () {
  },
  methods: {
    searchData () {
      this.loadData()
    },
    details (item) {
      this.editItemId = item.id
      this.item = item
    },
    // edit (item) {
    //   this.editItemId = item.id
    // },
    remove (item) {
      this.changeStatus(agriMarketingServiceBaseUrl, MasterMarketStatus, item, 'agriMarketing', 'marketList')
    },
    loadData () {
      const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      RestApi.getData(agriMarketingServiceBaseUrl, MasterMarketList, params).then(response => {
        if (response.success) {
            this.$store.dispatch('setList', this.getRelatinalData(response.data.data))
            // this.$store.dispatch('setList', response.data.data)
            this.paginationData(response.data)
        } else {
          this.$store.dispatch('setList', [])
        }
        this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
      })
    },
    getRelatinalData (data) {
      const divisionList = this.$store.state.agriMarketing.damData.divisionList
      const districtList = this.$store.state.agriMarketing.damData.districtList
      const upazilaList = this.$store.state.agriMarketing.damData.upazilaList

      const listData = data.map(item => {
        const divisionObject = divisionList.find(data => data.value === item.division_id)
        const districtObject = districtList.find(data => data.value === item.district_id)
        const upazilaObject = upazilaList.find(data => data.value === item.upazila_id)
        const marketTypeObject = this.marketTypeList.find(data => data.value === parseInt(item.market_type))

        const DivisionData = {
           division_name: divisionObject !== undefined ? divisionObject.text : '',
           division_name_bn: divisionObject !== undefined ? divisionObject.text_bn : ''
          }
        const DistrictData = {
          district_name: districtObject !== undefined ? districtObject.text : '',
          district_name_bn: districtObject !== undefined ? districtObject.text_bn : ''
          }
        const UpazilaData = {
          upazilla_name: upazilaObject !== undefined ? upazilaObject.text : '',
          upazilla_name_bn: upazilaObject !== undefined ? upazilaObject.text_bn : ''
          }
        const MarketTypeData = {
          market_type_name: marketTypeObject !== undefined ? marketTypeObject.text_en : '',
          market_type_name_bn: marketTypeObject !== undefined ? marketTypeObject.text_bn : ''
          }
        const HatDayArray = item.hat_days
        let hatDayData = HatDayArray.map(hatDayItem => {
            const hatDayObject = this.hatDayList.find(hatDay => hatDay.value === parseInt(hatDayItem))
            return hatDayObject !== undefined ? hatDayObject.text : ''
        })
        let hatDayDataBn = HatDayArray.map(hatDayItem => {
            const hatDayObject = this.hatDayList.find(hatDay => hatDay.value === parseInt(hatDayItem))
            return hatDayObject !== undefined ? hatDayObject.text_bn : ''
        })
        hatDayData = hatDayData.join(', ')
        hatDayDataBn = hatDayDataBn.join(', ')
        const infrasturectureArray = item.infrastructure_id
        let infaData = infrasturectureArray.map(infaItem => {
            const infaObject = this.$store.state.agriMarketing.commonObj.infrastructureList.find(infa => infa.value === parseInt(infaItem))
            return infaObject !== undefined ? infaObject.text_en : ''
        })
        let infaDataBn = HatDayArray.map(infaItem => {
            const infaObject = this.$store.state.agriMarketing.commonObj.infrastructureList.find(infa => infa.value === parseInt(infaItem))
            return infaObject !== undefined ? infaObject.text_bn : ''
        })
        infaData = infaData.join(', ')
        infaDataBn = infaDataBn.join(', ')
        const communicationArray = item.communication_linkage_id
        let communicationData = communicationArray.map(communicationItem => {
            const communicationObject = this.$store.state.agriMarketing.commonObj.communicationLinkageList.find(communication => communication.value === parseInt(communicationItem))
            return communicationObject !== undefined ? communicationObject.text_en : ''
        })
        let communicationDataBn = HatDayArray.map(communicationItem => {
            const communicationObject = this.$store.state.agriMarketing.commonObj.communicationLinkageList.find(communication => communication.value === parseInt(communicationItem))
            return communicationObject !== undefined ? communicationObject.text_bn : ''
        })
        communicationData = communicationData.join(', ')
        communicationDataBn = communicationDataBn.join(', ')
        const destinationArray = item.product_destination
        let destinationData = destinationArray.map(destinationItem => {
            const destinationObject = this.$store.state.agriMarketing.commonObj.designationOfProductList.find(designation => designation.value === parseInt(destinationItem))
            return destinationObject !== undefined ? destinationObject.text_en : ''
        })
        let destinationDataBn = destinationArray.map(destinationItem => {
            const destinationObject = this.$store.state.agriMarketing.commonObj.designationOfProductList.find(designation => designation.value === parseInt(destinationItem))
            return destinationObject !== undefined ? destinationObject.text_bn : ''
        })
        destinationData = destinationData.join(', ')
        destinationDataBn = destinationDataBn.join(', ')
        const vehicleArray = item.vehicle_id
        let vehicleData = vehicleArray.map(vehicleItem => {
            const vehicleObject = this.$store.state.agriMarketing.commonObj.vehicleList.find(vehicle => vehicle.value === parseInt(vehicleItem))
            return vehicleObject !== undefined ? vehicleObject.text_en : ''
        })
        let vehicleDataBn = vehicleArray.map(vehicleItem => {
            const vehicleObject = this.$store.state.agriMarketing.commonObj.vehicleList.find(vehicle => vehicle.value === parseInt(vehicleItem))
            return vehicleObject !== undefined ? vehicleObject.text_bn : ''
        })
        vehicleData = vehicleData.join(', ')
        vehicleDataBn = vehicleDataBn.join(', ')

        return Object.assign({}, item, DivisionData, DistrictData, UpazilaData, MarketTypeData, { hat_day: hatDayData, hat_day_bn: hatDayDataBn, infa: infaData, infa_bn: infaDataBn, communication: communicationData, communication_bn: communicationDataBn, destination: destinationData, destination_bn: destinationDataBn, vehicle: vehicleData, vehicle_bn: vehicleDataBn })
      })
      return listData
    },
     getDistrictList (divisionId = null) {
            return this.$store.state.agriMarketing.damData.districtList.filter(item => item.division_id === divisionId)
      },
      getUpazilaList (districtId = null) {
          const upazilaList = this.$store.state.agriMarketing.damData.upazilaList
          if (districtId) {
              return upazilaList.filter(upazila => upazila.district_id === districtId)
          }
          return upazilaList
      },
     getUnionList (upazillaId = null) {
        const unionList = this.$store.state.commonObj.unionList.filter(item => item.status === 0)
        if (upazillaId) {
            return unionList.filter(union => union.Subdistrict_id === upazillaId)
        }
        return unionList
    },
    pdfExportDetails () {
        this.$refs.details.pdfExport()
    },
    pdfExport () {
    const reportTitle = this.$t('crop_price_config.market_list')
        ExportPdf.exportPdfDetails(warehouseServiceBaseUrl, '/master-warehouse-report-heading/detail', 13, reportTitle, this.listData, this)
    },
    headerDataExcel () {
        RestApi.getData(warehouseServiceBaseUrl, '/master-warehouse-report-heading/detail/13').then(response => {
            if (response.success) {
                const orgList = this.$store.state.commonObj.organizationProfileList.find(item => item.value === 13)
                const orgName = typeof orgList !== 'undefined' ? orgList.text_en : ''
                const orgNameBn = typeof orgList !== 'undefined' ? orgList.text_bn : ''
                this.headerExcelDefault.orgName = orgName
                this.headerExcelDefault.orgNameBn = orgNameBn
                this.headerExcelDefault.address = response.data.address
                this.headerExcelDefault.address_bn = response.data.address_bn
            }
        })
    }
  }
}
</script>
