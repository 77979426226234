// Commodity Group
export const commodityList = '/crop-price-info/config/commodity-group/list'
export const commodityStore = '/crop-price-info/config/commodity-group/store'
export const commodityUpdate = '/crop-price-info/config/commodity-group/update'
export const commodityStatus = '/crop-price-info/config/commodity-group/toggle-status'
export const commodityDestroy = '/crop-price-info/config/commodity-group/destroy'
// Commodity Name
export const commodityNameList = '/crop-price-info/config/commodity-name/list'
export const commodityNameStore = '/crop-price-info/config/commodity-name/store'
export const commodityNameUpdate = '/crop-price-info/config/commodity-name/update'
export const commodityNameStatus = '/crop-price-info/config/commodity-name/toggle-status'
export const commodityNameDestroy = '/crop-price-info/config/commodity-name/destroy'
// alertPercentage
export const alertPercentageList = '/crop-price-info/config/master-alert-percentages/list'
export const alertPercentageStore = '/crop-price-info/config/master-alert-percentages/store'
export const alertPercentageUpdate = '/crop-price-info/config/master-alert-percentages/update'
export const alertPercentageStatus = '/crop-price-info/config/master-alert-percentages/toggle-status'
export const alertPercentageDestroy = '/crop-price-info/config/master-alert-percentages/destroy'
// Price Type
export const priceTypeList = '/crop-price-info/config/price-type/list'
export const priceTypeStore = '/crop-price-info/config/price-type/store'
export const priceTypeUpdate = '/crop-price-info/config/price-type/update'
export const priceTypeStatus = '/crop-price-info/config/price-type/toggle-status'
export const priceTypeDestroy = '/crop-price-info/config/price-type/destroy'
// CropPriceInfo->Commodity Sub Group API
export const commoditySubGroupList = '/crop-price-info/config/commodity-sub-group/list'
export const commoditySubGroupStore = '/crop-price-info/config/commodity-sub-group/store'
export const commoditySubGroupUpdate = '/crop-price-info/config/commodity-sub-group/update'
export const commoditySubGroupStatus = '/crop-price-info/config/commodity-sub-group/toggle-status'
export const commoditySubGroupDestroy = '/crop-price-info/config/commodity-sub-group/destroy'

// Measurement Unit
export const MeasurementUnitList = '/crop-price-info/config/master-measurement-units/list'
export const MeasurementUnitStatus = '/crop-price-info/config/master-measurement-units/toggle-status'
export const MeasurementUnitStore = '/crop-price-info/config/master-measurement-units/store'
export const MeasurementUnitUpdate = '/crop-price-info/config/master-measurement-units/update'
export const MeasurementUnitDestroy = '/crop-price-info/config/master-measurement-units/destroy'

// Price Collector Profile
export const PriceCollectorProfileList = '/price-collector/config/profile/list'
export const PriceCollectorProfileStatus = '/price-collector/config/profile/toggle-status'
export const PriceCollectorProfileStore = '/price-collector/config/profile/store'
export const PriceCollectorProfileUpdate = '/price-collector/config/profile/update'
export const PriceCollectorProfileDestroy = '/price-collector/config/profile/destroy'
export const PriceCollectorProfileApprove = '/price-collector/config/profile/approve/'
export const PriceCollectorProfileReject = '/price-collector/config/profile/reject/'

// Master Market
export const MasterMarketList = '/crop-price-info/config/master-markets/list'
export const MasterMarketPdfImage = '/crop-price-info/config/master-markets/pdf-image'
export const MasterMarketDetails = 'crop-price-info/config/master-markets/details/'
export const MasterMarketStatus = '/crop-price-info/config/master-markets/toggle-status'
export const MasterMarketStore = '/crop-price-info/config/master-markets/store'
export const MasterMarketUpdate = '/crop-price-info/config/master-markets/update'
export const MasterMarketDestroy = '/crop-price-info/config/master-markets/destroy'

// Price Entry
export const PriceEntryList = '/crop-price-info/cpi/price-entry/list'
export const MarketPriceList = '/crop-price-info/cpi/price-entry/market-price-list'
export const marketGrowerPriceList = '/crop-price-info/cpi/price-entry/market-grower-price-list'
export const marketWeeklyPriceList = '/crop-price-info/cpi/price-entry/market-weekly-price-list'
export const PriceEntryStore = '/crop-price-info/cpi/price-entry/store'
export const PriceEntryUpdate = '/crop-price-info/cpi/price-entry/update'

// Master Comment
export const commentList = '/crop-price-info/config/master-comment/list'
export const commentStore = '/crop-price-info/config/master-comment/store'
export const commentUpdate = '/crop-price-info/config/master-comment/update'
export const commentStatus = '/crop-price-info/config/master-comment/toggle-status'
export const commentDestroy = '/crop-price-info/config/master-comment/destroy'

export const userList = '/user/user-list'
export const userPrivilegeStore = '/crop-price-info/config/user-privilege/store'
export const userPrivilegeList = '/crop-price-info/config/user-privilege/user-list'
